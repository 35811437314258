@charset "UTF-8";
/*
ユーティリティ系おまとめファイル
*/
/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/
/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/
/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/
/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/
@media screen and (max-width: 568px) {
  #top .slider-container {
    height: 370px !important; } }

@media screen and (max-width: 568px) {
  #top .slider-container .rev_slider {
    height: 370px !important; } }

#top .tp-bgimg {
  background-image: url(/inc/image/top/mainimg.jpg) !important; }

#top .tp-parallax-wrap {
  top: 250px !important; }
  @media screen and (max-width: 568px) {
    #top .tp-parallax-wrap {
      top: 160px !important;
      left: 0; } }
  #top .tp-parallax-wrap h1 {
    line-height: 1.4 !important;
    font-weight: 500 !important;
    letter-spacing: 0.04em !important; }
    @media screen and (max-width: 568px) {
      #top .tp-parallax-wrap h1 {
        font-size: 20px !important; } }

@media screen and (max-width: 568px) {
  #top .tp-fullwidth-forcer {
    height: 370px !important; } }

#top .officelink {
  margin: 0 auto;
  color: #fff;
  background-size: cover;
  background-position: center center;
  display: flex;
  align-items: center;
  height: 360px; }
  @media screen and (max-width: 768px) {
    #top .officelink {
      height: 250px; } }
  #top .officelink h2 {
    font-size: 36px;
    line-height: 1.4; }
    @media screen and (max-width: 768px) {
      #top .officelink h2 {
        font-size: 1.7em; } }
  #top .officelink p {
    font-size: 16px; }
    @media screen and (max-width: 768px) {
      #top .officelink p {
        font-size: 14px; } }
  #top .officelink .btn {
    padding: 15px 32px;
    border-width: 2px;
    font-size: 12px;
    border-radius: 4px; }
    @media screen and (max-width: 768px) {
      #top .officelink .btn {
        padding: 8px 32px; } }
